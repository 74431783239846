import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Issues from "../components/issues";

const IssuesPage = ({ data }) => {
  const issues = data.issues.nodes;

  return (
    <Layout>
      <Seo title="Tous les numéros" />
      <Issues issues={issues} title="Tous les numéros" isMainSection />
    </Layout>
  );
};

export default IssuesPage;

export const query = graphql`
  query {
    issues: allContentfulIssue(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        ...issueFields
      }
    }
  }
`;
