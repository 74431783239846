import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import SectionHeading from "./section-heading";

SwiperCore.use([A11y]);

const Issues = ({ issues, title, link, linkLabel, isMainSection }) => (
  <section className="section section-issues" aria-labelledby="issues-title">
    <div className="container">
      <SectionHeading
        id="issues-title"
        title={title}
        link={link}
        linkLabel={linkLabel}
        isMainSection={isMainSection}
      />
      <div className="section-carousel">
        <Swiper
          spaceBetween={16}
          slidesPerView="auto"
          breakpoints={{
            768: {
              spaceBetween: 40,
            },
            1024: {
              spaceBetween: 0,
            },
          }}
        >
          {issues.map((issue) => (
            <SwiperSlide key={issue.id}>
              {issue.cover && (
                <figure>
                  <GatsbyImage
                    image={
                      issue.cover.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={issue.cover.description || ""}
                  />
                </figure>
              )}
              <Link to={`/numeros/${issue.slug}/`}>
                N<sup>o</sup> {issue.number} – {issue.dateRange}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  </section>
);

export default Issues;
